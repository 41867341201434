<main class="w-full font-body bg-slate-950 flex justify-between overflow-hidden"
  [ngClass]="{
    'v2': (isReportingV2$ | async),
    'group/container themeable': isThemeable(),
    'h-screen' : !(isReportingV2$ | async),
    'min-h-screen flex-col' : (isReportingV2$ | async)
  }"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin">

  @if((isReportingV2$ | async)){
    <app-product-top-nav [product]="product"></app-product-top-nav>
  }
  <div class="flex-1 flex justify-between h-full" [ngClass]="{'overflow-x-auto' : !(isReportingV2$ | async)}">
    <div class="flex justify-between z-40 sticky top-0 bottom-0 border-r dark:border-slate-800/60 v2:border-none">
      <router-outlet name="sidebar"></router-outlet>
    </div>

    <section cdkScrollable class="flex-1 flex flex-col justify-between relative overflow-x-hidden" [ngClass]="{'overflow-y-auto' : !(isReportingV2$ | async)}">
      <div class="flex-1 flex flex-col justify-between bg-white dark:bg-slate-950 transition-all">
        @if(!(isReportingV2$ | async)){
          <div class="sticky top-0 z-40 py-2.5 pl-8 pr-10 bg-white/90 dark:bg-slate-950/90 backdrop-blur-sm text-black/70 dark:text-white/70 dark:hover:text-white transition-all flex items-center justify-between gap-5 border-b border-gray-100 dark:border-slate-950">
            <div class="flex grow items-center justify-between">
              <div>
                <router-outlet name="breadcrumbs"></router-outlet>
              </div>
              <div class="flex gap-3.5 items-center">
                <app-activity-icon [orgId]="product.orgId"></app-activity-icon>
                <app-theme-switcher />
              </div>
            </div>
            <app-user-orgs-menu />
          </div>
        }
        <header class="w-full text-sm px-10 dark:text-white border-b dark:border-transparent v2:border-none">
          <div class="-mx-10">
            <router-outlet name="notifications"></router-outlet>
          </div>
          <router-outlet name="product-page-header"></router-outlet>
        </header>
        <div class="grow py-6 px-6 relative bg-soft-blue transition-all flex flex-col justify-between v2:py-0"
          [ngClass]="{'dark:bg-slate-950': isThemeable()}">
          <div class="flex-1">
            <router-outlet></router-outlet>
          </div>
          @if (!(isReportingV2$ | async)) {
            <app-footer class="v2:hidden"></app-footer>
          } @else {
            <div class="py-1.5"></div>
          }
        </div>
      </div>
    </section>
  </div>
</main>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="toggled$ | async">
  <div class="fixed right-0 bottom-0 top-0 fade-in-left overflow-y-auto w-[370px] bg-white shadow-lg z-[999] p-4 space-y-8">
    <div class="flex items-center justify-between">
      <div class="font-medium text-sm flex items-center space-x-2.5">
        <app-svg-images svgName="bell-alert" class="h-5"></app-svg-images>
        <span>{{ 'app.navigation.notifications.title' | translate }}</span>
      </div>
      <button class="p-1 active" (click)="toggleActivityFeed()" class="h-5">
        <app-svg-images svgName="x-mark"></app-svg-images>
      </button>
    </div>
    <div class="space-y-3">
      <app-activity-content></app-activity-content>
    </div>
  </div>
</ng-template>

<div [ngClass]="{ 'group/container themeable': isThemeable(), 'v2': (isReportingV2$ | async)}">
  <router-outlet name="aux"></router-outlet>
</div>
