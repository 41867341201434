import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { USER_MANAGEMENT_ROUTE } from '../app.constants';
import { MainContentSidenavComponent } from "../../views/main-content-sidenav/main-content-sidenav.component";
import { ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot, Routes } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  private currentRoute: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() {
  }

  getRoute() {
    return this.currentRoute.value;
  }

  setRoute(route) {
    this.currentRoute.next(route.url);
  }

  replaceProduct(productId): string[] {
    if (this.currentRoute.value.includes(USER_MANAGEMENT_ROUTE)) {
      this.setRoute({ url: '/products' });
      return [];
    }
    ;
    const url = this.currentRoute.value.replace(/[0-9]+/, productId);
    const split = url.split('/');
    // detect if we are viewing a scenario
    const pathIds = url.match(/\d{1,}/g);
    if (this._findTotalCount(url) === 0) {
      return ['/products', productId];
    } else if (pathIds.length > 1) {
      // if we are viewing a scenario we need to go back to base product level.
      return split.slice(0, 4);
    }
    return split;
  }

  private _findTotalCount(str) {
    let count = 0;

    for (const ch of str) {
      if (ch >= '0' && ch <= '9') {
        count++;
      }
    }
    return count;
  }

  findParent(activatedRoute: ActivatedRoute) {
    const findMainComponentSidenav = (route) => {
      if (route.component && route.component === MainContentSidenavComponent) {
        return route;
      }
      return findMainComponentSidenav(route.parent);
    };
    return findMainComponentSidenav(activatedRoute.parent)
  }

  findTitleInObject(routes: ActivatedRouteSnapshot[], targetPath: string): RouterStateSnapshot | undefined {
    for (const route of routes) {
      if (route.routeConfig?.path?.toString() === targetPath) {
        return {
          url: route.url.toString(),
          root: route
        }
      }

      if (route.children) {
        const found = this.findTitleInObject(route.children, targetPath);
        if (found) {
          return found;
        }
      }
    }
    return undefined;
  }

  findActiveRouteInOutlet(activatedRoute: ActivatedRouteSnapshot, outlet = 'aux', route = '') {
    const findOutlet = (children: ActivatedRouteSnapshot[]) => {
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (child.outlet === outlet) {
          return this.findTitleInObject(child.children, route);
        }
        if (i === children.length - 1) {
          return findOutlet(child.children);
        }
      }
    };
    return findOutlet(activatedRoute.children);
  }

  buildTitle(routerStateSnapshot: RouterStateSnapshot) {
    if (routerStateSnapshot.url.includes("aux")) {
      const activeRoute = this.extractLastSegment(routerStateSnapshot.url);
      return this.findActiveRouteInOutlet(routerStateSnapshot.root, 'aux', activeRoute) ?? routerStateSnapshot;
    }
    return routerStateSnapshot;
  }

  extractLastSegment(url: string): string {
    // Remove any surrounding parentheses
    const cleanedUrl = url.replace(/[()]/g, '');

    // Remove query parameters and fragments (anything after '?' or '#')
    const urlWithoutQuery = cleanedUrl.split('?')[0].split('#')[0];

    // Split the URL by slashes and get the last part
    const segments = urlWithoutQuery.split('/');
    return segments.pop() || ''; // Return the last segment, or an empty string if not found
  }
}
