import { Component, inject, Input } from '@angular/core';
import { IProduct } from '../../model/product.model';
import { Store } from '@ngrx/store';
import { AppState, selectAllNonFavoriteScenarios, selectFavouriteScenarios } from 'src/app/store';
import { filter } from 'rxjs';

@Component({
  selector: 'app-product-top-nav',
  templateUrl: './product-top-nav.component.html',
  styles: `
    :host {
      display: block;
    }
  `
})
export class ProductTopNavComponent {
  @Input() product: IProduct;

  private store: Store<AppState> = inject(Store);
  protected favouriteScenarios$ = this.store.select(selectFavouriteScenarios).pipe(filter(scenarios => !!scenarios?.length));
  protected scenarios$ = this.store.select(selectAllNonFavoriteScenarios).pipe(filter(scenarios => !!scenarios?.length));

}
