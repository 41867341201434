@if (isProcessing(notification)) {
  <app-scenario-progress [scenario]="scenario"></app-scenario-progress>
}
<a [routerLink]="goToScenario(notification.message)" class="no-decoration-link">
  <span class="d-flex align-items-center py-2 justify-content-between">
    <h3 class="m-0 text-[14px] mr-1">{{ scenario.name }}</h3>
    <app-button variant="default" [appTooltip]="error" tooltipState="error" (onClick)="goToScenario(notification.message)">
      <app-state [state]="notification.message" [isStyled]="false"></app-state>
    </app-button>
  </span>
</a>
    
<div class="d-flex align-items-center justify-content-between">
  <small>
    <span class="text-grey" translate="activity.actionedBy"></span>
    {{ notification.actionedBy?.length ? notification.actionedBy : ('activity.userNamePlaceholder' | translate) }}
  </small>
  <small>
    {{ notification.createdAt | date:'d MMM y HH:mm'}}
  </small>
</div>
@if (notification.updatedBy) {
  <div class="d-flex align-items-center justify-content-between">
    <small>
      <span class="text-grey" translate="activity.updatedBy"></span>
      {{ notification.updatedBy }}
    </small>
    <small>
      {{ notification.completedAt | date:'d MMM y HH:mm'}}
    </small>
  </div>
}